// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
    production: false,
    stage: "dev",

    versionPollingInterval: 1000 * 10, // 10sec

    apiUrlPrefix: "https://dev-api.brame-gamification.com",
    keyCloakApiUrlPrefix: "https://dev-api.brame-gamification.com/keycloak",
    campaignDataUrlPrefix: "https://dev-api.brame-gamification.com/campaigns-data",
    buildServiceApiUrlPrefix: "https://dev-api.brame-gamification.com/build",
    analyticsEndpoint: "analytics.brame-gamification.com",
    resourceServiceApiUrlPrefix: "https://dev-api.brame-gamification.com/campaign-resource",
    // Serverless api
    serverlessApiBaseUrl: "https://dev-serverless-api.brame-gamification.com",
    surveyApiUrlPrefix: "https://dev-serverless-api.brame-gamification.com/survey-game/api",
    memoryApiBaseUrl: "https://dev-serverless-api.brame-gamification.com/memory-game",
    quizApiBaseUrl: "https://dev-serverless-api.brame-gamification.com/quiz",
    scratchCardApiBaseUrl: "https://dev-serverless-api.brame-gamification.com/scratch-card",
    pennySpinTheWheelApiBaseUrl: "https://dev-serverless-api.brame-gamification.com/penny-stw",
    pennyPathApiBaseUrl: "https://dev-serverless-api.brame-gamification.com/penny-path-v2",
    pennyPenaltyApiBaseUrl:"https://dev-serverless-api.brame-gamification.com/penny-penalty",
    advancedSpinTheWheelApiBaseUrl: "https://dev-serverless-api.brame-gamification.com/advanced-spin-the-wheel",
    pennySlotApiBaseUrl:"https://dev-serverless-api.brame-gamification.com/penny-slot-machine",
    prizeManagementApiBaseUrl: "https://dev-serverless-api.brame-gamification.com/prize-management",
    pennySlotMachineApiBaseUrl: "https://dev-serverless-api.brame-gamification.com/penny-slot-machine",
    timeZonesApiBaseUrl: "https://dev-serverless-api.brame-gamification.com/time-zone",
    eventsApiBaseUrl: "https://dev-serverless-api.brame-gamification.com/events",

    campaignPreviewBaseUrl: "https://dev-preview.brame-gamification.com",
    campaignDataStorage: "https://dev-brame-campaign-data-storage.s3.amazonaws.com/campaign-data",
    pennyLeadsExportUrl: "https://d544yodqlwbwo6f6byajprrinm0axjkw.lambda-url.eu-central-1.on.aws",
    memoryGameLeadsExportUrl: "https://2h6otet5iad55r4spyb3fl24mm0uezhk.lambda-url.eu-central-1.on.aws",
    pennySlotMachineLeadsExportUrl: "https://6bwdxmpnpz7qajo7eefeivho540htjjc.lambda-url.eu-central-1.on.aws",
    pennyPathLeadsExportUrl: "https://ofaqab24bm2o35jt2vfafv7e3m0hjknr.lambda-url.eu-central-1.on.aws",
    pennyPenaltyLeadsExportUrl: "https://idzur46nyqxwszxyr244rdwikq0rloxo.lambda-url.eu-central-1.on.aws",
    advancedSpinTheWheelExportUrl: "https://6ami2xktrlvvl3exs2wr6r5zlm0okznl.lambda-url.eu-central-1.on.aws",

    integrationApp: {
        selfHosted: true,
        authEndpoint: "https://dev-serverless-api.brame-gamification.com/integration-app",
        approvedIntegrations: [
            { key: "hubspot", flowKey: "brame-lead-universal-flow" },
            { key: "mailchimp", flowKey: "brame-lead-universal-flow" },
            { key: "salesforce-pardot", flowKey: "brame-lead-universal-flow" },
        ],
    },

    isDemoEnv: false,
    enableDemo:false,

    cookies: {
        brame_at: "brame_at",
        refresh_brame_at: "refresh_brame_at",
        session: "SESSION",
    },
    contextKeyName: "brame-context",

    // Fusion charts
    // eslint-disable-next-line max-len
    fusionChartsKey: "PtB2dF-7wB4C5C3D5A3G3F3A2B8B7D2B3F6tgoD3B1d1fdzoG4I3I-7jD-17A3D5B3D5D4B3J4C3D7C5C2B4A4G1D2G1rmoE6E1C4gI4B3C8zyhD3D2E3vraA4B2C2zbnD1H4B3A5B3F4F3H3G4A19A32aikC6D3RH4moA-31A7C8A3wfA2IC1C5rA-16mD2D5F1A2B2B7A3D6A3C2E4E2D2C3u==",
    // Mixpanel
    mixpanelKey: "2a989804524f15cbdc8d6833074cbb4f",
    mixpanelProdEnv: false,
    backendAnalyticsEvents: {
        SUPPORTED_GAMES: [
            "quiz",
            "memory-game",
            "scratch-card",
            "survey",
        ],
        SUPPORTED_EVENTS: ["game_started", "game_finished", "lead_created"],
    },

    // Feature flags
    feautreFlagAppIdentifier: "3t9lzc2",
    feautreFlagProfileIdentifier: "7f7ef57",
    feautreFlagEnvIdentifier: "l7wqvd1",
    featureFlagClientConfig: {
        region: "eu-central-1",
        credentials: {
            accessKeyId: "AKIATOHVODOV3D2XD7OV",
            secretAccessKey: "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",
        },
    },
    leadsExports: {
        endpoint: 'https://segf2gfhmrovkfbfx7ntljrm340zyurm.lambda-url.eu-central-1.on.aws/'
    },
    configCat: {
        sdkKey: 'configcat-sdk-1/pKDcCBJx0k2HOFPyJbWnQg/n0saKuZhPUWsfu_ESW0Dfw',
    },
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
