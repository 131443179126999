import { Directive, Input, TemplateRef, ViewContainerRef } from "@angular/core";
import { FeatureFlagsService } from "@services/feature-flags/feature-flags.service";
import { FeatureFlagConfig } from "@models/feature-flags/FeatureFlagConfig";

@Directive({ selector: "[featureFlag]" })
export class FeatureFlagsDirective {
    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private featureFlagService: FeatureFlagsService
    ) {
    }

    hasView = false;

    @Input() set featureFlag(feature: FeatureFlagConfig) {
        if (
            this.featureFlagService.isFeatureFlagEnabled(feature) && !this.hasView
        ) {
            this.viewContainer.createEmbeddedView(this.templateRef);
            this.hasView = true;
        } else {
            this.viewContainer.clear();
            this.hasView = false;
        }
    }

    @Input() set featureFlagElse(elseTemplateRef: TemplateRef<any>) {
        if (!this.hasView) {
            this.viewContainer.createEmbeddedView(elseTemplateRef);
        }
    }
}
