import { Inject, Injectable } from "@angular/core";
import { FeatureFlagConfig } from "@models/feature-flags/FeatureFlagConfig";
import { IConfigCatClient } from "configcat-js";
import { CONFIGCAT_CLIENT } from "../../constants";

@Injectable({ providedIn: "root" })
export class FeatureFlagsService {
    constructor(@Inject(CONFIGCAT_CLIENT) private readonly configCatClient: IConfigCatClient) {
    }

    isFeatureFlagEnabled(feature: FeatureFlagConfig): boolean {
        return this.configCatClient.snapshot().getValue(feature, false);
    }
}
